import Modal from '@mozaic-ds/freemarker/js/modules/_modal.js';
const loader = `
  <div class="mc-loader">
    <svg class="mc-loader__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
    >
      <circle class="mc-loader__path" cx="50%" cy="50%" r="9" />
    </svg>
  </div> 
`;
class ArmPopinDsaModal {
  modal;
  constructor() {
    this.modal = new Modal({
      'classContent': 'arm-popin-dsa-modal'
    });
  }

  /**
   * Open modal
   */
  open() {
    this.modal.open();
  }

  /**
   * Close modal
   */
  close() {
    this.modal.close();
  }
}
lm.DOMReady(() => {
  const armPopinDsaModal = new ArmPopinDsaModal();
  document.addEventListener("arm-popin-dsa-open", event => {
    const request = new XMLHttpRequest();
    document.getElementById('arm-popin-dsa-modal-content').innerHTML = loader;
    armPopinDsaModal.open();
    function setModalContent() {
      if (request.status === 200) {
        document.getElementById('arm-popin-dsa-modal-content').innerHTML = this.response;
      } else {
        document.getElementById('arm-popin-dsa-modal-content').innerHTML = document.querySelector('#arm-popin-dsa-modal .arm-popin-dsa-empty').innerHTML;
      }
    }
    request.addEventListener('load', setModalContent);
    request.open('GET', '/application/legalInfo/' + event.detail);
    request.send();
  }, false);
  document.querySelector('#arm-popin-dsa-button-close').addEventListener('click', () => {
    armPopinDsaModal.close();
  });
});